<template>
  <div>
    <NavbarComponent />
    <PostComponent />
  </div>
</template>

<script>
import NavbarComponent from '../components/NavbarComponent.vue';
import PostComponent from '../components/PostComponent.vue';

export default {
  components: {
    NavbarComponent,
    PostComponent
  },
};
</script>