<template>
  <!-- SEARCHING NAVBAR -->
  <div class="d-flex">
    <v-text-field class="pr-5 ml-5 primary-color" v-model="isbn" label="Inserisci ISBN"></v-text-field>
    <btn class="bg-primary-color login-btn mr-5" @click="searchPostByISBN">
      <span class="mdi mdi-magnify"></span>
    </btn>
    <btn class="bg-primary-color login-btn mr-5" @click="fetchBookData">
      <span class="mdi mdi-plus"></span>
    </btn>
  </div>

  <!-- GET POST -->
  <div v-if="posts.length > 0" class="d-flex flex-wrap posts-layout">
    <v-card
      v-for="post in posts"
      :key="post.id"
      class="mx-auto my-8 text-left"
      elevation="5"
      max-width="350"
    >
      <v-card-item>
        <v-card-title>
          <div class="mdi mdi-account primary-color font-bold">{{ post.name + ' ' + post.surname}}</div>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <div class="fs-16 font-bold"><span class="mdi mdi-information custom-info"></span>Informazioni sul testo</div>
        <div class="pl-3 pb-2">{{ post.description }}</div>

        <div class="fs-16 font-bold"><span class="mdi mdi-tag custom-tag"></span>Il testo contiene appunti personali</div>
        <div class="pl-3 pb-2" v-if="post.notes">Si</div><div class="pl-3 pb-2" v-else>No</div>

        <div class="fs-16 font-bold"><span class="mdi mdi-map-marker custom-marker"></span>Città di provenienza</div>
        <div class="pl-3 pb-2">{{ post.city }}</div>

        <div class="fs-16 font-bold"><span class="mdi mdi-map-marker custom-marker"></span>Scuola di provenienza</div>
        <div class="pl-3 pb-2">{{ post.school }}</div>

        <div class="fs-16 font-bold"><span class="mdi mdi-cash custom-cash"></span>Prezzo</div>
        <div class="pl-3 pb-2">{{ post.prize }}€</div>

        <div class="fs-16 font-bold"><span class="mdi mdi-share-variant"></span>Come contattarmi</div>
      </v-card-text>
    </v-card>
  </div>

  <!-- MESSAGGIO NESSUN POST TROVATO -->
  <div v-if="isSearchExecuted && posts.length === 0" class="mt-5">
    <p class="primary-color fs-20">OPS, non è ancora presente alcun annuncio in riferimento a questo ISBN</p>
  </div>

  <!-- ADD POST -->
  <v-dialog v-model="dialog" width="auto" @open="resetForm">
    <v-card max-width="800">
      <v-card-title>Pubblica Libro</v-card-title>

      <v-card-text>
        <div v-if="successMessage">
          <div class="text-center mdi mdi-check-circle fs-46 custom-check-circle"></div>
          <div class="success-message">{{ successMessage }}</div>
        </div>
        
        <div v-else-if="book">
          <!-- BOOK'S TITLE -->
          <div class="pb-3 book-title">{{ book.title }}</div>
          
          <!-- BOOK'S IMAGE -->
          <v-img :src="book.image" class="pb-3" alt="Copertina del libro" max-width="150"></v-img>
          
          <!-- BOOK'S INFORMATION -->
          <div class="section-title"><span class="mdi mdi-information custom-info"></span>Informazioni sul testo *</div>
          <v-textarea variant="outlined" no-resize v-model="description"></v-textarea>

          <!-- BOOK'S NOTES -->
          <div class="section-title"><span class="mdi mdi-tag custom-tag"></span>Il testo contiene appunti personali? *</div>
          <v-checkbox label="Si" v-model="notes"></v-checkbox>

          <!-- BOOK'S LOCATION -->
          <div class="section-title"><span class="mdi mdi-map-marker custom-marker"></span>Città e scuola di provenienza *</div>
          <div class="pb-3 d-flex flex-wrap select-group">
            <!-- Regione -->
            <div class="custom-select">
              <div class="select-box" @click="toggleDropdown('regions')" :class="{ disabled: false }">
                {{ selectedRegion ? selectedRegion.denominazione_regione : 'Seleziona Regione' }}
              </div>
              <ul v-if="isDropdownOpen.regions" class="options">
                <li v-for="region in regions" :key="region.codice_regione" @click="selectRegion(region)">
                  {{ region.denominazione_regione }}
                </li>
              </ul>
            </div>

            <!-- Provincia -->
            <div class="custom-select">
              <div class="select-box" @click="toggleDropdown('provinces')" :class="{ disabled: !selectedRegion }">
                {{ selectedProvince ? selectedProvince.denominazione_provincia : 'Seleziona Provincia' }}
              </div>
              <ul v-if="isDropdownOpen.provinces && selectedRegion" class="options">
                <li v-for="province in provinces" :key="province.sigla_provincia" @click="selectProvince(province)">
                  {{ province.denominazione_provincia }}
                </li>
              </ul>
            </div>

            <!-- Comune -->
            <div class="custom-select">
              <div class="select-box" @click="toggleDropdown('comuni')" :class="{ disabled: !selectedProvince }">
                {{ selectedComune ? selectedComune.denominazione_ita : 'Seleziona Comune' }}
              </div>
              <ul v-if="isDropdownOpen.comuni && selectedProvince" class="options">
                <li v-for="comune in comuni" :key="comune.denominazione_ita" @click="selectComune(comune)">
                  {{ comune.denominazione_ita }}
                </li>
              </ul>
            </div>

            <!-- Scuola -->
            <div class="custom-select">
              <div class="select-box" @click="toggleDropdown('schools')" :class="{ disabled: !selectedComune }">
                {{ selectedSchool ? selectedSchool.denominazione : 'Seleziona Scuola' }}
              </div>
              <ul v-if="isDropdownOpen.schools && selectedComune" class="options">
                <li v-for="school in schools" :key="school.denominazione" @click="selectSchool(school)">
                  {{ school.denominazione }}
                </li>
              </ul>
            </div>
          </div>
          <!-- BOOK'S OWNER CONTACTS -->
          <div class="pb-3 d-flex flex-wrap contact-section">
            <div>
              <div class="section-title"><span class="mdi mdi-email custom-email"></span>Indirizzo mail *</div>
              <v-textarea variant="outlined" rows="1" no-resize autocomplete="email" v-model="email"></v-textarea>
            </div>
            <div>
              <div class="section-title"><span class="mdi mdi-phone custom-phone"></span>Numero di telefono</div>
              <v-textarea variant="outlined" rows="1" no-resize autocomplete="phone" v-model="phone"></v-textarea>
            </div>
            <div>
              <div class="section-title"><span class="mdi mdi-facebook custom-facebook"></span>Username Facebook</div>
              <v-textarea variant="outlined" rows="1" no-resize autocomplete="facebook" v-model="facebook"></v-textarea>
            </div>
            <div>
              <div class="section-title"><span class="mdi mdi-twitter custom-twitter"></span>Username Twitter</div>
              <v-textarea variant="outlined" rows="1" no-resize autocomplete="twitter" v-model="twitter"></v-textarea>
            </div>
          </div>
          <!-- BOOK'S PRIZE -->
          <div class="section-prize">
            <div class="section-title"><span class="mdi mdi-cash custom-cash"></span>Prezzo *</div>
            <v-textarea variant="outlined" rows="1" no-resize v-model="prize"></v-textarea>
          </div>
        </div>
        
        <div v-else>
          <p>Nessun libro trovato. Inserisci un ISBN valido.</p>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="savePost" v-if="!successMessage">Salva Annuncio</v-btn>
        <v-btn text @click="closeDialog">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isbn: '',
      posts: [],
      book: null,
      dialog: false,
      description: '',
      notes: false,
      prize: '',
      email: '',
      phone: '',
      facebook: '',
      twitter: '',
      selectedRegion: null,
      selectedProvince: null,
      selectedComune: null,
      selectedSchool: null,
      regions: [],
      provinces: [],
      comuni: [],
      schools: [],
      user_id: '1',
      isDropdownOpen: {
        regions: false,
        provinces: false,
        comuni: false,
        schools: false
      },
      isSearchExecuted: false,
      successMessage: ''
    };
  },
  created() {
    this.fetchRegions();
  },
  methods: {
    async fetchBookData() {
      if (this.isbn.trim() === '') {
        alert('Inserisci un ISBN valido.');
        return;
      }

      const url = `https://www.libraccio.it/uc/ProductList.aspx?idList=${this.isbn}`;

      try {
        const response = await axios.get(url);
        this.parseHTML(response.data);

        if (this.book) {
          this.dialog = true;  // Mostriamo il modal per aggiungere il post
        } else {
          alert('Nessun libro trovato.');
        }
      } catch (error) {
        console.error('Errore durante il recupero dei dati:', error);
        alert('Errore durante il recupero dei dati.');
      }
    },
    parseHTML(htmlString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      const titleElement = doc.querySelector('.title a');
      const title = titleElement ? titleElement.innerText : null;

      const imageElement = doc.querySelector('.image img');
      const image = imageElement ? imageElement.getAttribute('src') : null;

      if (title && image) {
        this.book = { title, image };
      } else {
        this.book = null;
      }
    },
    async searchPostByISBN() {
      if (this.isbn.trim() === '') {
        alert('Inserisci un ISBN valido.');
        return;
      }

      this.isSearchExecuted = false;

      try {
        const response = await axios.get(`/api/getPost.php?isbn=${this.isbn}`);
        console.log('Risposta API:', response.data); // Debug: visualizza la risposta
        this.posts = Array.isArray(response.data) ? response.data : []; // Assegna solo se è un array
        console.log('Lunghezza posts:', this.posts.length); // Debug: visualizza la lunghezza

        this.isSearchExecuted = true;
      } catch (error) {
        console.error('Errore durante la ricerca dei post:', error.response); // Debug: visualizza l'errore
        alert('Errore durante la ricerca dei post.');
        this.isSearchExecuted = true;
      }
    },
    toggleDropdown(type) {
      this.isDropdownOpen[type] = !this.isDropdownOpen[type];
    },
    selectRegion(region) {
      this.selectedRegion = region;
      this.isDropdownOpen.regions = false;
      this.fetchProvinces(region.codice_regione);
      this.selectedProvince = null;
      this.selectedComune = null;
      this.selectedSchool = null;
    },
    selectProvince(province) {
      this.selectedProvince = province;
      this.isDropdownOpen.provinces = false;
      this.fetchComuni(province.sigla_provincia);
      this.selectedComune = null;
      this.selectedSchool = null;
    },
    selectComune(comune) {
      this.selectedComune = comune;
      this.isDropdownOpen.comuni = false;
      this.fetchSchools(comune.denominazione_ita);
      this.selectedSchool = null;
    },
    selectSchool(school) {
      this.selectedSchool = school;
      this.isDropdownOpen.schools = false;
    },
    async fetchRegions() {
      try {
        const response = await axios.get('/api/getRegions.php');
        this.regions = response.data;
      } catch (error) {
        console.error('Errore durante il caricamento delle regioni:', error);
      }
    },
    async fetchProvinces(regionCode) {
      try {
        const response = await axios.get(`/api/getProvinces.php?codice_regione=${regionCode}`);
        this.provinces = response.data;
      } catch (error) {
        console.error('Errore durante il recupero delle province:', error);
      }
    },
    async fetchComuni(provinceCode) {
      try {
        const response = await axios.get(`/api/getComuni.php?sigla_provincia=${provinceCode}`);
        this.comuni = response.data;
      } catch (error) {
        console.error('Errore durante il recupero dei comuni:', error);
      }
    },
    async fetchSchools(comuneName) {
      try {
        const response = await axios.get(`/api/getSchools.php?denominazione_ita=${comuneName}`);
        this.schools = response.data;
      } catch (error) {
        console.error('Errore durante il recupero delle scuole:', error);
      }
    },
    async savePost() {
      const postData = {
        isbn: this.isbn,
        description: this.description,
        notes: this.notes,
        prize: this.prize,
        city: this.selectedComune ? this.selectedComune.denominazione_ita : null,
        region: this.selectedRegion ? this.selectedRegion.denominazione_regione : null,
        province: this.selectedProvince ? this.selectedProvince.denominazione_provincia : null,
        school: this.selectedSchool ? this.selectedSchool.denominazione : null,
        facebook: this.facebook,
        twitter: this.twitter,
        phone: this.phone,
        email: this.email,
        user_id: this.user_id,
      };

      console.log('Dati inviati:', postData);

      try {
      const response = await axios.post('/api/addPost.php', postData, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      if (response.data.success) {
        this.successMessage = 'Annuncio salvato con successo!';
        // Chiudi il modal dopo 10 secondi
        setTimeout(() => {
          this.dialog = false;
          this.resetForm();
        }, 10000);
      } else {
        alert('Errore: ' + response.data.message);
      }

      } catch (error) {
          console.error('Errore durante il salvataggio:', error);
          alert('Errore durante il salvataggio dei dati.');
        }
    },
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
          this.resetForm();
        }, 1000);
    },
    resetForm() {
      this.successMessage = '';
      this.description = '';
      this.notes = false;
      this.email = '';
      this.phone = '';
      this.facebook = '';
      this.twitter = '';
      this.prize = '';
      this.selectedRegion = null;
      this.selectedProvince = null;
      this.selectedComune = null;
      this.selectedSchool = null;
    },
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 180px;
}

.select-box {
  padding: 12px;
  border: 1px solid #b0bec5;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.select-box:hover {
  border-color: #ff8400;
  box-shadow: 0 0 8px #c46704;
}

.options {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #b0bec5;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.options li {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.options li:hover {
  background-color: #ff8400;
  color: white;
}

.select-box.disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.select-box.disabled:hover {
  border-color: #e0e0e0;
  box-shadow: none;
}

.select-group {
  gap: 16px;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
}

.book-title {
  font-size: 16px;
  font-weight: bold;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.section-prize {
  width: 100px;
}

.contact-section {
  gap: 16px;
}

.posts-layout {
  gap: 25px;
}

/* ICONS CUSTOMIZATION */
.custom-info {
  color: rgb(236, 201, 0);
}

.custom-tag {
  color: rgb(134, 6, 160);
}

.custom-marker {
  color: rgb(255, 155, 24);
}

.custom-email {
  color: rgb(184, 21, 0);
}

.custom-phone {
  color: rgb(3, 179, 3);
}

.custom-facebook {
  color: #1e47ff;
}

.custom-twitter {
  color: #1d92ff;
}

.custom-cash {
  color: #129e00;
}

.custom-check-circle {
  color: #30e718;
}
</style>